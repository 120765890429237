const default_state = {
  id: undefined,
  name: undefined
};

function reducer(state = default_state, action) {
  switch (action.type) {
    case "SET_COMPANY":
      state.id = action.id;
      state.name = action.name;
      return state;
    default:
      return state;
  }
}

export default reducer;
