const default_state = {
  filter: "",
  search: ""
};

function reducer(state = default_state, action) {
  switch (action.type) {
    case "SET_FILTER":
      state.filter = action.phrase;
      return state;
    case "SET_SEARCH":
      state.search = action.phrase;
      return state;
    default:
      return state;
  }
}

export default reducer;
