import { Model, ArrayModel } from "objectmodel";

const Owner = new Model({
  name: String,
  email: [String],
  phone: [String]
});

const Company = new Model({
  name: String,
  legal_name: String,
  company_id: String,
  description: String,
  owner: ArrayModel(Owner),
  splash: String
});

export default Company;
