import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { useSelector } from "react-redux";
import { firebase, auth } from "store/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import "style/main.scss";
import Splash from "pages/Splash";
import Login from "pages/Login";
import Dashboard from "pages/Dashboard";
import Companies from "pages/Companies";
import Company from "pages/Company";
import Issues from "pages/Issues";
import Projects from "pages/Projects";
import Search from "pages/Search";

const Application = () => {
  const [user, initialising] = useAuthState(auth);
  const current_company_id = useSelector(state => state.companies.id);
  const login = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    auth.signInWithPopup(provider);
  };

  if (initialising) {
    return <Splash />;
  }

  if (!user) {
    return <Login onLogin={login} />;
  }

  return (
    <Router>
      <Switch>
        <Route path="/companies/:id" component={Company} />
        <Route path="/companies" component={Companies} />
        <Route path="/issues" component={Issues} />
        <Route path="/projects" component={Projects} />
        <Route path="/search" component={Search} />
        <Route
          path="/"
          exact
          render={props =>
            current_company_id ? (
              <Dashboard />
            ) : (
              <Redirect {...props} to={"/companies"} />
            )
          }
        />
      </Switch>
    </Router>
  );
};

export default Application;
