import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import copy from "clipboard-copy";

import IconButton from "@material/react-icon-button";
import MaterialIcon from "@material/react-material-icon";
import { Headline4, Body1, Subtitle1 } from "@material/react-typography";

const LegalInfo = styled(Subtitle1)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Summary = ({ company, onChange = () => {} }) => {
  const dispatch = useDispatch();

  const copy_to_clipboard = text => {
    dispatch({
      type: "ADD_SNACK",
      message: `"${text}" copied to clipboard.`,
      timeoutMs: 4000
    });
    copy(text);
  };

  return (
    <div>
      <Headline4>{company.name}</Headline4>
      <LegalInfo>
        <span>
          {company.legal_name}
          <IconButton
            className="mdc-icon-button--small"
            onClick={() => copy_to_clipboard(company.legal_name)}
          >
            <MaterialIcon icon="filter_none" />
          </IconButton>
        </span>
        <span>
          {company.company_id}
          <IconButton
            className="mdc-icon-button--small"
            onClick={() => copy_to_clipboard(company.company_id)}
          >
            <MaterialIcon icon="filter_none" />
          </IconButton>
        </span>
      </LegalInfo>
      <Body1>{company.description}</Body1>
    </div>
  );
};

export default Summary;
