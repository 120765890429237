import React from "react";

import DialogDialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton
} from "@material/react-dialog";

const Dialog = ({
  open,
  onClose,
  title,
  button_text,
  button_action,
  children
}) => (
  <DialogDialog open={open} onClose={onClose} className="form__dialog">
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogFooter>
      <DialogButton action="cancel">Cancel</DialogButton>
      <DialogButton raised action={button_action} isDefault>
        {button_text}
      </DialogButton>
    </DialogFooter>
  </DialogDialog>
);

export default Dialog;
