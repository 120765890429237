import React from "react";
import { useSelector } from "react-redux";

import useSessionStorage from "lib/useSessionStorage";
import Form from "./Form";
import Dialog from "./Dialog";

const NewDialog = ({
  open,
  onCancel,
  onComplete,
  initial_state = {
    name: "",
    legal_name: "",
    owners: "",
    description: "",
    splash: ""
  }
}) => {
  const db = useSelector(state => state.db);
  const form_name = "new_company_form";

  const [data, set_data, clear_data] = useSessionStorage(
    form_name,
    initial_state
  );

  const onClose = action => {
    switch (action) {
      case "cancel":
      case "close":
        onCancel();
        break;
      case "add":
        db.collection("companies")
          .add(data)
          .then(function() {
            console.log("Document successfully written!");
          })
          .catch(function(error) {
            console.error("Error writing document: ", error);
          });
        clear_data();
        onComplete();
        break;
      default:
        console.log("Unknown action:", action);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Add company"
      button_text="Add"
      button_action="add"
    >
      <Form data={data} onChange={set_data} />
    </Dialog>
  );
};

export default NewDialog;
