import React from "react";

import Layout from "components/Layout";
import { Headline3, Body1 } from "@material/react-typography";

const Dashboard = () => (
  <Layout title="Dashboard">
    <Headline3>Dashboard</Headline3>
    <Body1>An overview of important information ofr the selected company</Body1>
  </Layout>
);

export default Dashboard;
