import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Form from "./Form";
import Dialog from "./Dialog";

const EditDialog = ({
  open,
  onCancel,
  onComplete,
  initial_state = {
    name: "",
    legal_name: "",
    owners: "",
    description: "",
    splash: ""
  }
}) => {
  const db = useSelector(state => state.db);
  const [data, set_data] = useState(initial_state);

  useEffect(() => {
    set_data(initial_state);
  }, [initial_state]);

  const onClose = action => {
    switch (action) {
      case "cancel":
      case "close":
        onCancel();
        break;
      case "save":
        db.collection("companies")
          .doc(data.id)
          .set(data)
          .then(function() {
            console.log("Document successfully written!");
          })
          .catch(function(error) {
            console.error("Error writing document: ", error);
          });
        onComplete();
        break;
      default:
        console.log("Unknown action:", action);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Edit company"
      button_text="Save"
      button_action="save"
    >
      <Form data={data} onChange={set_data} />
    </Dialog>
  );
};

export default EditDialog;
