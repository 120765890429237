import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { TopAppBarFixedAdjust } from "@material/react-top-app-bar";
import { Snackbar } from "@material/react-snackbar";

import Header from "components/Header";
import Drawer from "components/Drawer";

const Article = styled.article`
  padding: 1rem 2rem;
`;

const Layout = ({ title, children, ...props }) => {
  const [menu_open, set_menu_open] = useState(false);

  const snack = useSelector(state => state.snacks[0]);
  const dispatch = useDispatch();

  return (
    <>
      <Header onMenu={() => set_menu_open(!menu_open)} title={title} />
      <Drawer open={menu_open} onClose={() => set_menu_open(false)} />
      <TopAppBarFixedAdjust>
        <Article>{children}</Article>
      </TopAppBarFixedAdjust>
      {snack && (
        <Snackbar
          {...snack}
          onClose={() => {
            dispatch({ type: "CLEAR_SNACK" });
            dispatch({ type: "REMOVE_SNACK" });
          }}
        />
      )}
    </>
  );
};

export default Layout;
