import { createLogicMiddleware } from "redux-logic";

//import user_logic from 'flows/user';
//import game_logic from 'flows/game';

const logic = [
  //  ...user_logic,
  //  ...game_logic
];

export default (auth, db, cloudstore) => {
  const dependancies = {
    auth,
    db,
    cloudstore
  };

  return createLogicMiddleware(logic, dependancies);
};
