const default_state = {
  current: undefined,
  list: [
    {
      id: "ACC-102",
      name: "Fel på sidan",
      description:
        "Ett stavfel på /hosting/pricing gör att det ser ut som att vi säljer elefanter...",
      reporter: "Hannes Elvemyr",
      assigned: undefined,
      externalUrl: "https://accodeing.com/hosting/pricing"
    },
    {
      id: "ACC-105",
      name: "Heimr deployments fungerar inte ibland",
      description:
        "Vissa byggen går inte igenom eftersom deploymentsteget failar. Behöver undersökas.",
      reporter: "Jonas Schubert Erlandsson",
      assigned: "Jonas Schubert Erlandsson",
      externalUrl: "https://heimr.se/"
    },
    {
      id: "ACC-106",
      name: "Ny version av Clerk",
      description:
        "Det finns en ny version av Clerk som vi behöver få ut på alla statiska sidor. Klientbiblioteket har breaking changes och behöver uppdateras i sync med serverversionen.",
      reporter: "Jonas Schubert Erlandsson",
      assigned: undefined,
      externalUrl: "https://npm.accodeing.com/clerk"
    }
  ]
};

function reducer(state = default_state, action) {
  switch (action.type) {
    //    case "SET_FILTER":
    //      state.filter = action.phrase;
    //      return state;
    //    case "SET_SEARCH":
    //      state.search = action.phrase;
    //      return state;
    default:
      return state;
  }
}

export default reducer;
