import React from "react";
import styled from "styled-components";

import TextField, { HelperText, Input } from "@material/react-text-field";
import MaterialIcon from "@material/react-material-icon";

const StyledTextField = styled(TextField)`
  width: 100%;

  & + .mdc-text-field-helper-line {
    margin-bottom: 16px;
  }
`;

const Field = ({ name, help, value = "", update, rows, ...props }) => (
  <StyledTextField
    {...props}
    label={name}
    helperText={help && <HelperText>{help}</HelperText>}
    onTrailingIconSelect={() => update("")}
    trailingIcon={<MaterialIcon role="button" icon="clear" />}
  >
    <Input
      rows={rows}
      value={value}
      onChange={({ target: { value } }) => update(value)}
    />
  </StyledTextField>
);

export default Field;
