import React from "react";

import { Body1 } from "@material/react-typography";
import Button from "@material/react-button";
import Dialog, { DialogTitle, DialogContent } from "@material/react-dialog";

const Login = ({ onLogin }) => (
  <Dialog open={true}>
    <DialogTitle>Login</DialogTitle>
    <DialogContent>
      <Button onClick={onLogin}>Login with Google</Button>
      <Body1>
        This application requires authentication. Please login using the methods
        provided above. If you are unsure of how you should proceed please
        contact your administrator.
      </Body1>
    </DialogContent>
  </Dialog>
);

export default Login;
