import React from "react";
import { useSelector } from "react-redux";

import { Cell, Grid, Row } from "@material/react-layout-grid";
import MaterialIcon from "@material/react-material-icon";
import Fab from "@material/react-fab";

import Layout from "components/Layout";
import { Card } from "components/Issue";

import filter from "lib/filter";

const filter_map = {
  name: "String",
  description: "String"
};

const Issues = () => {
  const phrase = useSelector(state => state.search.filter);
  const issues = useSelector(state => state.issues.list);

  const filtered_issues = filter(issues, phrase, filter_map);

  return (
    <Layout title="Issues">
      <Grid>
        <Row>
          {filtered_issues.map(issue => (
            <Cell columns={12} key={issue.name}>
              <Card {...issue} />
            </Cell>
          ))}
        </Row>
      </Grid>
      <Fab className="app-fab--absolute" icon={<MaterialIcon icon="add" />} />
    </Layout>
  );
};

export default Issues;
