import React from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import styled from "styled-components";

import CardCard, {
  CardPrimaryContent,
  CardMedia,
  CardActions,
  CardActionIcons,
  CardActionButtons
} from "@material/react-card";
import { Body1, Headline5 } from "@material/react-typography";
import IconButton from "@material/react-icon-button";
import MaterialIcon from "@material/react-material-icon";

const Header = styled(Headline5)`
  margin-left: 16px;
`;

const Body = styled(Body1)`
  margin: 0 16px;
`;

const Actions = styled(CardActions)`
  padding: 16px;
`;

const Card = ({ history, index, id, name, description, splash, edit }) => {
  const dispatch = useDispatch();

  return (
    <CardCard
      onClick={() => {
        dispatch({ type: "SET_COMPANY", id, name });
        history.push("/");
      }}
    >
      <CardPrimaryContent>
        <CardMedia wide imageUrl={splash} />
      </CardPrimaryContent>

      <Header>{name}</Header>
      <Body>{description}</Body>

      <Actions>
        <CardActionButtons>
          <IconButton
            onClick={event => {
              dispatch({ type: "SET_COMPANY", id, name });
              history.push(`/companies/${id}`);
              event.stopPropagation();
            }}
            aria-label="See details and edit"
            title="See details and edit"
          >
            <MaterialIcon icon="edit" />
          </IconButton>
        </CardActionButtons>
        <CardActionIcons>
          <IconButton aria-label="Communication log" title="Communication log">
            <MaterialIcon icon="notes" />
          </IconButton>
        </CardActionIcons>
      </Actions>
    </CardCard>
  );
};

export default withRouter(Card);
