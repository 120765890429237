const default_state = [];

// message	String	Message to show in the snackbar
// timeoutMs	Number	Timeout in milliseconds when to close snackbar.
// closeOnEscape	Boolean	Closes popup on "Esc" button if true.
// actionText	String	Text for action button

function reducer(state = default_state, action) {
  switch (action.type) {
    case "ADD_SNACK":
      state.push(action);
      return state;
    case "CLEAR_SNACK":
      state[0] = undefined;
      return state;
    case "REMOVE_SNACK":
      return state.slice(1);
    default:
      return state;
  }
}

export default reducer;
