import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { Cell, Grid, Row } from "@material/react-layout-grid";
import MaterialIcon from "@material/react-material-icon";
import Fab from "@material/react-fab";

import Layout from "components/Layout";
import { Card, NewDialog, EditDialog } from "components/Company";

import filter from "lib/filter";

const filter_map = ["name", "description", "owner.name"];

const Companies = ({ history }) => {
  const db = useSelector(state => state.db);
  const phrase = useSelector(state => state.search.filter);
  const [companies = [], loading = true, error] = useCollectionData(
    db.collection("companies"),
    { idField: "id" }
  );

  const filtered_companies = filter(companies, phrase, filter_map);
  const [show_new_dialog, set_show_new_dialog] = useState(false);
  const [edit_company_index, set_edit_company_index] = useState(undefined);
  const edit_company = index => {
    set_edit_company_index(index);
  };

  if (error) {
    return (
      <Layout title="Companies">
        <strong>Error: {JSON.stringify(error)}</strong>;
      </Layout>
    );
  }

  if (loading) {
    return (
      <Layout title="Companies">
        <span>Collection: Loading...</span>
      </Layout>
    );
  }

  if (phrase !== "" && filtered_companies.length === 0) {
    return (
      <Layout title="Companies">
        <span>No matching companies found.</span>
      </Layout>
    );
  }

  return (
    <Layout title="Companies">
      <Grid>
        <Row>
          {filtered_companies.map((company, index) => (
            <Cell columns={4} key={company.id}>
              <Card
                index={index}
                {...company}
                edit={() => edit_company(index)}
              />
            </Cell>
          ))}
        </Row>
      </Grid>
      <NewDialog
        open={show_new_dialog}
        onCancel={() => set_show_new_dialog(false)}
        onComplete={() => set_show_new_dialog(false)}
      />
      <EditDialog
        open={edit_company_index !== undefined}
        onCancel={() => set_edit_company_index(undefined)}
        onComplete={() => set_edit_company_index(undefined)}
        initial_state={
          edit_company_index !== undefined ? companies[edit_company_index] : {}
        }
      />
      <Fab
        className="app-fab--absolute"
        onClick={() => set_show_new_dialog(true)}
        icon={<MaterialIcon icon="add" />}
      />
    </Layout>
  );
};

export default Companies;
