import React from "react";
import { useSelector } from "react-redux";

import Layout from "components/Layout";
import { Headline3, Body1 } from "@material/react-typography";

const Search = () => {
  const phrase = useSelector(state => state.search.search);
  return (
    <Layout title="Search">
      <Headline3>Results</Headline3>
      <Body1>Showing any matches for the phrase: {phrase}</Body1>
    </Layout>
  );
};

export default Search;
