import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import styled from "styled-components";

import TopAppBar, {
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle
} from "@material/react-top-app-bar";
import MaterialIcon from "@material/react-material-icon";

import OmniBox from "./OmniBox";

const Icon = styled.img`
  width: 48px;
  padding-left: 20px;
`;

const Spacer = styled.div`
  padding-left: 20px;
`;

const Header = ({ history, onMenu, title = "Customer portal" }) => {
  const current_company_name =
    useSelector(state => state.companies.name) || "All";

  return (
    <TopAppBar fixed>
      <TopAppBarRow>
        <TopAppBarSection align="start">
          <TopAppBarIcon navIcon tabIndex={0}>
            <MaterialIcon hasRipple icon="menu" onClick={onMenu} />
          </TopAppBarIcon>
          <Icon
            src="/logo.png"
            alt="Customer portal logotype, a stylized black hole with multi colored event horizon."
          />
          <TopAppBarTitle>
            {current_company_name} - {title}
          </TopAppBarTitle>
        </TopAppBarSection>
        <TopAppBarSection align="end" role="toolbar">
          <OmniBox />
          <Spacer />
          <TopAppBarIcon navIcon>
            <MaterialIcon
              hasRipple
              icon="people"
              onClick={() => history.push("/companies")}
            />
          </TopAppBarIcon>
        </TopAppBarSection>
      </TopAppBarRow>
    </TopAppBar>
  );
};

export default withRouter(Header);
