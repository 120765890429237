import React from "react";
import styled from "styled-components";

import CardCard, { CardActions, CardActionIcons } from "@material/react-card";
import { Body1, Headline5 } from "@material/react-typography";
import IconButton from "@material/react-icon-button";
import MaterialIcon from "@material/react-material-icon";

const Header = styled(Headline5)`
  margin-left: 16px;
`;

const Body = styled(Body1)`
  margin: 0 16px;
`;

const Actions = styled(CardActions)`
  padding: 16px;
`;

const Card = ({ name, description, reporter }) => (
  <CardCard>
    <Header>{name}</Header>
    <Body>{description}</Body>
    <Body>{reporter}</Body>
    <Actions>
      <CardActionIcons>
        <IconButton>
          <MaterialIcon icon="gesture" />
        </IconButton>
      </CardActionIcons>
    </Actions>
  </CardCard>
);

export default Card;
