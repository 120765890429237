import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyBaIwrLy8qM3Or-xfBCVJFY3SG0d_FpZc8",
  authDomain: "customer-portal-e07f7.firebaseapp.com",
  databaseURL: "https://customer-portal-e07f7.firebaseio.com",
  projectId: "customer-portal-e07f7",
  storageBucket: "customer-portal-e07f7.appspot.com",
  messagingSenderId: "597065896130",
  appId: "1:597065896130:web:f44062d42fe1177e"
};

firebase.initializeApp(config);
const auth = firebase.auth();
const db = firebase.firestore();
const cloudstore = firebase.storage();

export { firebase, auth, db, cloudstore };
