import React from "react";

import { Body1, Headline3 } from "@material/react-typography";
import MaterialIcon from "@material/react-material-icon";
import Fab from "@material/react-fab";

import Layout from "components/Layout";

const Projects = () => (
  <Layout title="Projects">
    <Headline3>Projects</Headline3>
    <Body1>List active projects for the company.</Body1>
    <Fab className="app-fab--absolute" icon={<MaterialIcon icon="add" />} />
  </Layout>
);

export default Projects;
