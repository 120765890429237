import { createBrowserHistory } from "history";
import { applyMiddleware, createStore, compose } from "redux";
import { routerMiddleware } from "connected-react-router";

import createRootReducer from "./reducers";
import createLogic from "./logic";
import { auth, db, cloudstore } from "./firebase";

export const history = createBrowserHistory();

const enhancers = window.__REDUX_DEVTOOLS_EXTENSION__
  ? compose(
      applyMiddleware(
        routerMiddleware(history),
        createLogic(auth, db, cloudstore)
      ),
      window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  : compose(
      applyMiddleware(
        routerMiddleware(history),
        createLogic(auth, db, cloudstore)
      )
    );

export default () => {
  const store = createStore(
    createRootReducer(history),
    {
      auth,
      db,
      cloudstore
    },
    enhancers
  );

  return store;
};
