import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { withRouter } from "react-router";

import Tab from "@material/react-tab";
import TabBar from "@material/react-tab-bar";

import Layout from "components/Layout";
import { Summary, General, Contact, Billing } from "components/Company/Tabs";
import Company from "models/Company";

const CompanyPage = ({ match }) => {
  const dispatch = useDispatch();
  const db = useSelector(state => state.db);
  const [raw_company = {}, loading = true, error] = useDocumentData(
    db.collection("companies").doc(match.params.id),
    { idField: "id" }
  );
  const [tab, set_tab] = useState(0);
  let company;

  if (!loading) {
    company = new Company(raw_company);
    dispatch({ type: "SET_COMPANY", id: company.id, name: company.name });
  }

  if (error) {
    return (
      <Layout title="Company details">
        <strong>Error: {JSON.stringify(error)}</strong>;
      </Layout>
    );
  }

  if (loading) {
    return (
      <Layout title="Company details">
        <span>Loading...</span>
      </Layout>
    );
  }

  return (
    <Layout title="Company details">
      <TabBar activeIndex={tab} handleActiveIndexUpdate={set_tab}>
        <Tab>
          <span className="mdc-tab__text-label">Summary</span>
        </Tab>
        <Tab>
          <span className="mdc-tab__text-label">General</span>
        </Tab>
        <Tab>
          <span className="mdc-tab__text-label">Contact</span>
        </Tab>
        <Tab>
          <span className="mdc-tab__text-label">Billing</span>
        </Tab>
      </TabBar>
      {tab === 0 && <Summary company={company} />}
      {tab === 1 && <General company={company} />}
      {tab === 2 && <Contact company={company} />}
      {tab === 3 && <Billing company={company} />}
    </Layout>
  );
};

export default withRouter(CompanyPage);
