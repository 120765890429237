import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router";

import MaterialIcon from "@material/react-material-icon";
import TextField, { Input } from "@material/react-text-field";

const OmniBox = ({ history }) => {
  const phrase = useSelector(state => state.search.filter);
  const dispatch = useDispatch();

  const handle_change = ({ target: { value } }) => {
    dispatch({ type: "SET_FILTER", phrase: value });
  };

  const handle_keypress = ({ key }) => {
    if (key === "Enter") {
      dispatch({ type: "SET_SEARCH", phrase: phrase });
      history.push("/search");
    } else if (key === "Escape") {
      dispatch({ type: "SET_FILTER", phrase: "" });
    }
  };

  return (
    <TextField
      dense
      onTrailingIconSelect={() => handle_change({ target: { value: "" } })}
      leadingIcon={<MaterialIcon role="button" icon="filter_list" />}
      trailingIcon={<MaterialIcon role="button" icon="clear" />}
      className="header__search"
    >
      <Input
        value={phrase}
        onChange={handle_change}
        onKeyDown={handle_keypress}
      />
    </TextField>
  );
};

export default withRouter(OmniBox);
