import dotize from "dotize";

/*
 * Converts complex objects into flat objects with dot notation keys and array
 * of string values.
 *
 * @example:
 *   object_to_dot_notation({
 *     name: "Carl",
 *     lastNames: ["Svensson", "Bodén"],
 *     more: [
 *       {
 *         label: true,
 *         name: "arbitrary",
 *         values: [1, 2, 3, 4]
 *       }
 *     ]
 *   });
 *   becomes:
 *   {
 *     "lastnames": ["svensson", "bodén"],
 *     "more.label": ["true"],
 *     "more.name": ["arbitrary"],
 *     "more.values": ["1", "2", "3", "4"],
 *     "name": ["carl"]
 *   }
 */
const object_to_dot_notation = object => {
  // Convert complex object to flat object with dot notation keys.
  const _dotize_result = dotize.convert(object);
  // New, wmpty result object to add the converted keys/values to.
  const result = {};

  for (let [key, value] of Object.entries(_dotize_result)) {
    // dotize returns array elements like `arguments[0].list[0].name = value0`,
    // we want them like `arguments.list.name = [value0,value1,...]`. This regex
    // removes the array notation from the key.
    const clean_key = key
      .replace(/\[\d+\]/gi, "")
      .toLowerCase()
      .trim();

    // All values should be arrays, sometimes with only one value in.
    if (!result[clean_key]) {
      result[clean_key] = [];
    }

    // Ensure that we have a clean string as the value
    const clean_value = value
      .toString()
      .toLowerCase()
      .trim();

    // Add value to value array for this key.
    result[clean_key].push(clean_value);
  }

  return result;
};

/*
 * Filters a collection of objects given a collection of objects, a phrase and
 * an array of dot notation paths to search.
 *
 * The collection will be reduced to the set of objects that has one or more
 * values in the given paths that contain the phrase.
 *
 * @param {array[object]} collection - The collection of objects to filter.
 * @param {string} phrase - The phrase to look for.
 * @param {array[string]} paths - Dot notating paths in the collection objects to search.
 *
 * @returns {array[objects]} A subset of the given collection.
 */
export default (collection = [], phrase = "", paths = []) => {
  // Filter by nothing, get everything back.
  if (phrase === "") {
    return collection;
  }

  // Someone is not reading the docs ...
  if (!Array.isArray(paths)) {
    console.log(
      'The paths argument should be an array of dot notation string paths, like "user.name.first", to search.'
    );
    return [];
  }

  // Filter by nothing, get everything back.
  if (paths === []) {
    return collection;
  }

  // Same convertion as for everything else.
  const clean_phrase = phrase.toLowerCase().trim();

  return collection.filter(item => {
    // Get a flat dot notation version of the input
    const dot_notation = object_to_dot_notation(item);

    // Return true if any of the paths in the collection contains the phrase.
    return paths.some(path => {
      const clean_path = path.toLowerCase().trim();

      // Return false if no value for the path exists in the object.
      if (!dot_notation[clean_path]) {
        return false;
      }

      // Return true if any of the values for the path contains the phrase.
      return dot_notation[clean_path].some(value =>
        value.includes(clean_phrase)
      );
    });
  });
};
