import React from "react";

import Field from "components/Company/Form/Field";

const NewForm = ({ data, onChange }) => (
  <>
    <Field
      name="Common name"
      help='Colloquial company name, like "Accodeing to you"'
      value={data.name}
      update={value => onChange({ ...data, name: value })}
    />

    <Field
      name="Legal name"
      help='Legal company name, like "Accodeing to you KB"'
      value={data.legal_name}
      update={value => onChange({ ...data, legal_name: value })}
    />

    <Field
      name="Owners name"
      help="First and last name. Comma seperate several owners"
      value={data.owners}
      update={value => onChange({ ...data, owners: value })}
    />

    <Field
      textarea
      name="Description"
      help="Description of the company and its focus"
      value={data.description}
      update={value => onChange({ ...data, description: value })}
    />

    <Field
      name="Splash image URL"
      help="URL to splash image for the company"
      value={data.splash}
      update={value => onChange({ ...data, splash: value })}
    />
  </>
);

export default NewForm;
