import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { underscore } from "lib/string";

import ImageUpload from "components/Form/ImageUpload";
import Field from "components/Company/Form/Field";

const TabPlane = styled.div`
  padding-top: 16px;
`;

const General = ({ company, onChange = () => {} }) => {
  const db = useSelector(state => state.db);
  const on_complete = url => {
    db.collection("companies")
      .doc(company.id)
      .set(
        {
          splash: url
        },
        { merge: true }
      );
  };

  return (
    <TabPlane>
      <ImageUpload
        folder={`${underscore(company.legal_name.toLowerCase())}/images`}
        file_base_name={"splash"}
        current_image_url={company.splash}
        on_complete={on_complete}
      />

      <Field
        name="Common name"
        help='Colloquial company name, like "Accodeing to you"'
        value={company.name}
        update={value => onChange({ ...company, name: value })}
      />

      <Field
        name="Legal name"
        help='Legal company name, like "Accodeing to you KB"'
        value={company.legal_name}
        update={value => onChange({ ...company, legal_name: value })}
      />

      <Field
        name="Company ID"
        help="Organisational number or legal ID"
        value={company.company_id}
        update={value => onChange({ ...company, company_id: value })}
      />

      <Field
        textarea
        rows={10}
        name="Description"
        help="Description of the company and its focus"
        value={company.description}
        update={value => onChange({ ...company, description: value })}
      />
    </TabPlane>
  );
};
export default General;
