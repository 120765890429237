import { combineReducers } from "redux-immer";
import { connectRouter } from "connected-react-router";
import produce from "immer";

import search from "reducers/search";
import companies from "reducers/companies";
import issues from "reducers/issues";
import snacks from "reducers/snacks";

const null_reducer = state => state;

export default history =>
  combineReducers(produce, {
    firebase: null_reducer,
    router: connectRouter(history),
    search,
    companies,
    issues,
    snacks
  });
