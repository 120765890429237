import React, { useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

import MaterialIcon from "@material/react-material-icon";
import { Headline4 } from "@material/react-typography";
import LinearProgress from "@material/react-linear-progress";

const DropTarget = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  cursor: pointer;
  margin-bottom: 32px;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #000;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    opacity: 1;
  }

  & .material-icons {
    font-size: 48px;
    position: relative;
    top: 12px;
    margin-right: 8px;
  }
`;

const StyledLinearProgress = styled(LinearProgress)`
  position: absolute;
  bottom: 0;
`;

const ImageUpload = ({
  folder,
  file_base_name,
  current_image_url,
  on_complete: external_on_complete
}) => {
  const dispatch = useDispatch();
  const [progress, set_progress] = useState(null);
  const cloudstore = useSelector(state => state.cloudstore);
  const file_input = useRef(null);

  const new_image_selected = useCallback(
    file => {
      const extension = file.name.split(".").slice(-1);
      const filename = file_base_name
        ? `${file_base_name}.${extension}`
        : file.name;
      const path = `${folder}/${filename}`;

      const on_progress = snapshot => {
        var progress = snapshot.bytesTransferred / snapshot.totalBytes;
        set_progress(progress);
      };

      const on_error = error => {
        dispatch({
          type: "ADD_SNACK",
          message: `Upload failed: "${error}"`,
          timeoutMs: 4000
        });
      };

      const on_complete = ({ ...props }) => {
        console.log("props", props);
        // Make sure the progress bar is actually at 100%
        set_progress(1);
        // Remove the progress bar after a pause
        window.setTimeout(() => set_progress(null), 1000);
        // Get the uploaded file's URL
        uploadTask.snapshot.ref.getDownloadURL().then(url => {
          // Call the external on_complete hook with the URL
          external_on_complete(url);
          // Show snackbar notice about the successful upload
          dispatch({
            type: "ADD_SNACK",
            message: "Image uploaded successfully.",
            timeoutMs: 4000
          });
        });
      };

      const uploadTask = cloudstore
        .ref()
        .child(path)
        .put(file);
      uploadTask.on("state_changed", on_progress, on_error, on_complete);
    },
    [cloudstore, dispatch, external_on_complete, file_base_name, folder]
  );

  const onDrop = useCallback(
    acceptedFiles => {
      new_image_selected(acceptedFiles[0]);
    },
    [new_image_selected]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <DropTarget
      style={{ backgroundImage: `url(${current_image_url})` }}
      onClick={() => file_input.current.click()}
      {...getRootProps()}
    >
      {isDragActive ? (
        <Headline4>
          <MaterialIcon icon="cloud_upload" />
          Drop file here to upload.
        </Headline4>
      ) : (
        <Headline4>
          <MaterialIcon icon="cloud_upload" />
          Click to upload replacement image.
          <br /> Or drag and drop an image to replace.
        </Headline4>
      )}
      {progress !== null && <StyledLinearProgress progress={progress} />}
      <input
        style={{ display: "none" }}
        ref={file_input}
        type="file"
        onChange={({ target }) => new_image_selected(target.files[0])}
        {...getInputProps()}
      />
    </DropTarget>
  );
};
export default ImageUpload;
