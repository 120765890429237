import React from "react";
import { withRouter } from "react-router";

import { auth } from "store/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import DrawerDrawer, {
  DrawerHeader,
  DrawerSubtitle,
  DrawerTitle,
  DrawerContent
} from "@material/react-drawer";
import MaterialIcon from "@material/react-material-icon";
import List, {
  ListItem,
  ListItemGraphic,
  ListItemText
} from "@material/react-list";

const items = [
  {
    icon: "list",
    primaryText: "Issues",
    to: "/issues"
  },
  {
    icon: "dashboard",
    primaryText: "Projects",
    to: "/projects"
  }
];

const Drawer = ({ location: { pathname }, history, open, onClose }) => {
  const [user] = useAuthState(auth);

  const selected = items.findIndex(item => item.to === pathname);

  return (
    <DrawerDrawer modal open={open} onClose={onClose}>
      <DrawerHeader>
        <DrawerTitle>Accodeing to you</DrawerTitle>
        <DrawerSubtitle>{user.displayName}</DrawerSubtitle>
      </DrawerHeader>

      <DrawerContent>
        <List singleSelection selectedIndex={selected}>
          {items.map(({ icon, primaryText, to }) => (
            <ListItem key={to} onClick={() => history.push(to)}>
              <ListItemGraphic graphic={<MaterialIcon icon={icon} />} />
              <ListItemText primaryText={primaryText} />
            </ListItem>
          ))}
        </List>
      </DrawerContent>
    </DrawerDrawer>
  );
};

export default withRouter(Drawer);
