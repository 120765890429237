import React from "react";
import styled from "styled-components";

import { Headline1, Subtitle1 } from "@material/react-typography";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Splash = ({ onLogin }) => (
  <Container>
    <Headline1>Customer portal</Headline1>
    <Subtitle1>Loading ...</Subtitle1>
  </Container>
);

export default Splash;
